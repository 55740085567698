import htmx from 'htmx.org';
import Sortable from 'sortablejs';

// htmx recommends making itself global
// https://htmx.org/docs/#webpack
window.htmx = htmx;

htmx.onLoad((content) => {
  // Only apply the sorting logic to the project lists that can be edited, which
  // is represented by the presence of the 'data-can-edit' attribute.
  htmx.findAll(content, '#projects .project_list[data-can-edit]').forEach((el) => {
    Sortable.create(el, {
      group: "projects",
      // When a dragable is dropped outside of a drop target, revert it to its
      // original position.
      revertOnSpill: true,

      // Send a mutation command upon successful completion of the drop. Since
      // it could have been reverted when the draggable was spilled, we need to
      // check whether anything changed. If nothing changed, we should not
      // trigger a request.
      onEnd: (event) => {
        if (
          event.from === event.to &&
          event.oldIndex === event.newIndex
        ) {
          console.log('Drag resulted in no change');
          return;
        }

        const positions = [];
        htmx.findAll('[data-project-id]').forEach((el) => {
          positions.push(el.dataset.projectId);
        });
        const projectId = event.item.dataset.projectId;
        console.log(`Project ID ${projectId} moved from ` +
          `category ID ${event.from.dataset.categoryId} ` +
          `Index ${event.oldIndex} ` +
          `Active: (${event.from.dataset.isActive}) to ` +
          `category ID ${event.to.dataset.categoryId} ` +
          `Index ${event.newIndex} ` +
          `Active: (${event.to.dataset.isActive}). ` +
          `All positions: ${positions}`);
        const context = {
          values: {
            positions: positions,
            categoryFrom: event.from.dataset.categoryId,
            categoryTo: event.to.dataset.categoryId,
            isActiveFrom: event.from.dataset.isActive,
            isActiveTo: event.to.dataset.isActive
          }
        };
        htmx.ajax(
          'POST',
          `/projects/${projectId}/mutate`,
          context
        );
      }
    });
  });

  htmx.findAll(content, '#projects .dropdown').forEach((el) => {
    let listener = el.dataset.toggleHandler;
    if (!listener) {
      listener = htmx.on(el, 'click', (event) => {
        closeAllDropdowns(el);
        el.classList.toggle('is-active');
        event.stopPropagation();
      });
      el.dataset.toggleHandler = listener;
    }
  });

  if (content.classList.contains('modal')) {
    const modal = content;
    htmx.findAll(modal, '.modal-background, .modal-close, [data-modal-close]').forEach((closer) => {
      htmx.on(closer, 'click', () => {
        closeModal(modal);
      });
    });
  }

  htmx.findAll(content, '[data-click-to-copy]').forEach((clickToCopy) => {
    const input = htmx.find(clickToCopy, 'input[type="text"]');
    const button = htmx.find(clickToCopy, 'button');
    htmx.on(input, 'focus', () => {
      input.select();
      input.setSelectionRange(0, input.value.length);
    });
    htmx.on(input, 'blur', () => {
      input.setSelectionRange(0, 0);
    });
    htmx.on(button, 'click', () => {
      navigator.clipboard.writeText(input.value).then(
        () => {
          // Display to the user that the text has been copied.
          const tooltip = button.dataset.tooltip;
          button.setAttribute('data-tooltip', 'Copied');
          htmx.addClass(button, 'has-tooltip-active');
          // And after a bit of time, hide that tooltip and reset it for the
          // next time the user hovers over the button.
          setTimeout(() => {
            const resetTooltip = function() {
              htmx.removeClass(button, 'has-tooltip-active');
              button.setAttribute('data-tooltip', tooltip);
            };
            // If the pointer is elsewhere (ie, the button is not being hovered
            // over), then reset the tooltip and close it.
            if (!button.matches(':hover')) {
              resetTooltip();
            }
            // But, if the user is still hovering, then wait for them to move
            // the pointer out and then hide the message.
            else {
              const resetAndRemove = function() {
                resetTooltip();
                htmx.off(button, 'pointerout', resetAndRemove);
              };
              htmx.on(button, 'pointerout', resetAndRemove);
            }
          }, 2500);
        },
        (err) => { console.log('Failed to copy text', err); }
      );
      // Always blur the button's focus so that it does not have the weird
      // outline and put the button in a higher z-index than other tooltips.
      button.blur();
    });
  });

  htmx.findAll(content, '[data-chaos]').forEach((el) => {
    let chaos = el.dataset.chaos == 'true';
    if (chaos) {
      console.log("Turning ON chaos");
      htmx.on('htmx:configRequest', breakSomeRequests);
    }
  });
});

// Only connect the dropdown close event handler to the window once, so do it
// outside of the loading function call.
htmx.on(document, 'click', closeAllDropdowns);

htmx.on(document, 'keydown', (event) => {
  if (event.code === 'Escape') {
    closeAllModals();
  }
});

// Closes all dropdowns that are open, except for the element that is passed in
// (if there is one).  This is used to ensure that when an open dropdown is
// clicked on, it will be closed.  If we did not do this, then the dropdown
// would toggle off, then immediately toggle back on.
function closeAllDropdowns(skip) {
  htmx.findAll('#projects .dropdown.is-active').forEach((el) => {
    if (el !== skip) {
      el.classList.toggle('is-active');
    }
  });
}

function closeModal(modal) {
  modal.remove();
}

function closeAllModals() {
  htmx.findAll('.modal').forEach(closeModal);
}

function breakSomeRequests(event) {
  // Used for simulating errors... change the well formed request path to one
  // that has an invalid category_id.
  const path = event.detail.path;
  const chaos = [
    /\/projects\/new\?category_id=(\d+).*/,
    /\/projects\/(\d+)(\/(edit|mutate))?/
  ];
  chaos.forEach((re) => {
    if (re.exec(path)) {
      event.detail.path = path.replaceAll(/\d+/g, "NOT-A-NUMBER");
    }
  });
  const params = event.detail.parameters;
  Object.keys(params).forEach((param) => {
    let value = params[param];
    console.log(`may mutate value ${value}`);
    if (Array.isArray(value)) {
      value = value.map(unnumberize)
    }
    else {
      value = unnumberize(value);
    }
    params[param] = value;
  })
  console.log('New params', params);
}
function unnumberize(value) {
  if (value.replaceAll) {
    return value.replaceAll(/\d+/g, 'NOT-A-NUMBER');
  }
  return value;
}
htmx.on('app:dev', function (event) {
  if (event.detail.requestChaos) {
    console.log('Turning ON chaos');
    htmx.on('htmx:configRequest', breakSomeRequests);
  }
  else {
    console.log('Turning off chaos');
    htmx.off('htmx:configRequest', breakSomeRequests);
  }
});
